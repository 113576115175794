import { createContext } from 'react';
import { ImportedLog } from '../TypeScript/interfaces';

export interface GeologContextInterface {
  addLogs: (
    logs: Record<string, Record<string | number, Record<string, any>[]>>
  ) => void;
  getLogs: (
    logName: string,
    drillholeId: string | number
  ) => Record<string, any>[];
  logsMetadata: ImportedLog[];
  addLogsMetadata: (newLogsMetadata: ImportedLog[]) => void;
}

export const geologContext: React.Context<GeologContextInterface> =
  createContext({} as GeologContextInterface);
