/** @mui/material imports */
import { Alert, Button, IconButton, Snackbar, useTheme } from '@mui/material';
/** @mui/icons-material imports */
import CloseIcon from '@mui/icons-material/Close';
/** React imports */
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
/** Goldspot imports */
import { AlertObject } from '../../TypeScript/interfaces';
import { DialogErrorDetails } from '../../Components/Dialogs/DialogErrorDetails';

export interface AppAlertComponentProps {
  appAlert: AlertObject;
  key?: string;
  handleCloseAlert: () => void;
}
export const AppAlertComponent: React.FC<AppAlertComponentProps> = ({
  appAlert,
  key,
  handleCloseAlert,
}: AppAlertComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] =
    useState<boolean>(false);

  const handleOpenAlertDetails = (): void => {
    setIsDetailsDialogOpen(true);
  };

  const handleCloseAlertDetails = (): void => {
    setIsDetailsDialogOpen(false);
    handleCloseAlert();
  };

  const alertStyles =
    appAlert.severity === 'error'
      ? {
          border: `1px solid ${theme.palette.error.main}`,
        }
      : {};

  return (
    <Fragment key={key}>
      <Snackbar
        open={true}
        onClose={handleCloseAlert}
        autoHideDuration={appAlert.severity === 'error' ? 10000 : 5000}
      >
        <Alert
          severity={appAlert.severity}
          sx={alertStyles}
          action={
            <>
              {!!appAlert.details && (
                <Button
                  color={'inherit'}
                  size={'small'}
                  onClick={handleOpenAlertDetails}
                >
                  {t('BUTTON_DETAILS')}
                </Button>
              )}
              <IconButton
                color={'inherit'}
                size={'small'}
                onClick={handleCloseAlert}
              >
                <CloseIcon />
              </IconButton>
            </>
          }
        >
          {appAlert.message}
        </Alert>
      </Snackbar>
      <DialogErrorDetails
        isDetailsDialogOpen={isDetailsDialogOpen}
        onClose={handleCloseAlertDetails}
        severity={appAlert.severity}
        details={appAlert.details || ''}
      />
    </Fragment>
  );
};
