/**
 * Builds a query string from the given parameters object.
 *
 * @param {string[]} validKeys - A list of keys allowed in the query string
 * @param {Record<string, string | number | null>} params - The parameters object containing key-value pairs.
 * @returns {string} The query string constructed from the parameters object.
 */
export const parseQueryParams = (
  validKeys: string[],
  params?: Record<string, any>
): string => {
  if (!params) return '';

  const queryParams: string[] = [];

  validKeys.forEach((validKey: string) => {
    if (
      params.hasOwnProperty(validKey) &&
      params[validKey] !== undefined &&
      params[validKey] !== ''
    ) {
      queryParams.push(`${validKey}=${params[validKey]}`);
    }
  });

  const queryString: string = queryParams.join('&');

  if (queryString.length > 0) {
    return '?' + queryString;
  } else {
    return queryString;
  }
};
