import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';

// app-wide translation instance
import { t } from 'i18next';

I18n.putVocabularies(translations);
I18n.setLanguage('en');
// aws-amplify/ui languages as of Mar 9, 2022:
// de, en, es, fr, id, it, ja, kr, nl, pl, pt, zh, sv, tr

// overwrite aws-amplify/ui defaults with app-wide translations
I18n.putVocabularies({
  en: {
    'Back to Sign In': t('BACK_SIGN_IN'),
    Birthdate: t('BIRTHDATE'),
    'Change Password': t('CHANGE_PASSWORD'),
    Changing: t('CHANGING_PASSWORD'),
    Code: t('CODE'),
    'Confirm Password': t('CONFIRM_PASSWORD'),
    'Reset your Password': t('CONFIRM_RESET_PASSWORD_HEADING'),
    'Confirm Sign Up': t('CONFIRM_SIGNUP_HEADING'),
    'Confirm SMS Code': t('CONFIRM_SMS'),
    'Confirm TOTP Code': t('CONFIRM_TOTP'),
    Confirm: t('CONFIRM'),
    'Confirmation Code': t('CONFIRMATION_CODE'),
    Confirming: t('CONFIRMING'),
    'Create Account': t('CREATE_ACCOUNT'),
    'Creating Account': t('CREATING_ACCOUNT'),
    Email: t('EMAIL_ADDRESS'),
    'Enter your code': t('ENTER_CODE'),
    'Enter your username': t('ENTER_USERNAME'),
    'Family Name': t('FAMILY_NAME'),
    'Given Name': t('GIVEN_NAME'),
    'Forgot your password?': t('FORGOT_YOUR_PASSWORD'),
    'Forgot your password? ': t('FORGOT_YOUR_PASSWORD_LEGACY'),
    'Hide password': t('HIDE_PASSWORD'),
    Loading: t('LOADING'),
    Username: t('LOGIN_NAME'),
    'Middle Name': t('MIDDLE_NAME'),
    Name: t('NAME'),
    Nickname: t('NICKNAME'),
    'New password': t('NEW_PASSWORD'),
    Password: t('PASSWORD'),
    'Phone Number': t('PHONE_NUMBER'),
    'Preferred Username': t('PREFERRED_USERNAME'),
    Profile: t('PROFILE'),
    'Resend Code': t('RESEND_CODE'),
    'Reset your password': t('RESET_PASSWORD_HEADING'),
    'Reset Password': t('RESET_PASSWORD'),
    'Send code': t('SEND_CODE'),
    Sending: t('SENDING'),
    'Setup TOTP': t('SETUP_TOTP'),
    'Show password': t('SHOW_PASSWORD'),
    'Sign in': t('SIGN_IN_BUTTON'),
    'Sign In': t('SIGN_IN_TAB'),
    'Sign In with Amazon': t('SIGN_IN_WITH_AMAZON'),
    'Sign In with Apple': t('SIGN_IN_WITH_APPLE'),
    'Sign In with Facebook': t('SIGN_IN_WITH_FACEBOOK'),
    'Sign In with Google': t('SIGN_IN_WITH_GOOGLE'),
    'Sign in to your account': t('SIGN_IN'),
    'Create a new account': t('SIGN_UP_BUTTON'),
    'Signing in': t('SIGNING_IN_BUTTON'),
    Skip: t('SKIP'),
    Submit: t('SUBMIT'),
    Submitting: t('SUBMITTING'),
    'Verify Contact': t('VERIFY_CONTACT'),
    'Account recovery requires verified contact information':
      t('VERIFY_HEADING'),
    Verify: t('VERIFY'),
    Website: t('WEBSITE'),
  },
});
