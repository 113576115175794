import Backdrop from '@mui/material/Backdrop/Backdrop';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

const AppScopeLoadingComponent: React.FC<{}> = (): JSX.Element => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: '2000' }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default AppScopeLoadingComponent;
