import { AuthenticatedApiRequest } from 'gsf-util-react-auth';
import {
  GetProjectAllReportsParams,
  PercentageReportObject,
  ProjectAllReportList,
  ProjectObject,
  ProjectRequestObject,
  ProjectsList,
} from '../../TypeScript/interfaces';
import { ProjectObjectUpdate } from '../../TypeScript/types';
import { parseQueryParams } from '../helpers/parse-query-params';

export interface GetProjectsListParams {
  company: string;
  queryParams?: {
    /**
     * Title of the project. Generally a short text
     *
     * @example 'The great project'
     */
    title?: string;
    /**
     * Longer text explaining about the project
     *
     * @example 'This project is to collect all from A and from B'
     */
    description?: string;
    /**
     * The type of project as freely decided or agreed up on. Generaly will refer to a main service
     *
     * @example 'litholens', or 'tessellation'
     */
    type?: 'litholens' | 'tessellation';
    /**
     * If provided helps pagination by defining from where the next page starts. It will be a project ID
     *
     * @example 'the-x-project'
     */
    offset_token?: string;
    /**
     * Amount of items to be terurned. Default is 10.
     *
     * @example '6'
     */
    limit?: number;
  };
}

export const getProjectsList = ({
  company,
  queryParams,
}: GetProjectsListParams) => {
  const params: string[] = Object.keys(queryParams || {}).map(
    (key: string): string => {
      const value: string = (queryParams || ({} as any))[key];
      if (typeof value === 'undefined' || value === null || value === '') {
        return '';
      } else {
        return `${key}=${value}`;
      }
    }
  );
  return AuthenticatedApiRequest<ProjectsList>({
    method: 'get',
    apiName: 'Projects',
    path: `/${company}?${params.join('&')}`,
  });
};

export const getProject = (
  company: string,
  projectId: string,
  expand?: boolean
): Promise<ProjectObject> => {
  return new Promise((resolve, reject) => {
    let path: string = '';
    if (expand) {
      path = `/${company}/${projectId}?expand=true`;
    } else {
      path = `/${company}/${projectId}`;
    }
    AuthenticatedApiRequest({
      method: 'get',
      apiName: 'Projects',
      path,
    })
      .then((result: any) => {
        const projectObject: ProjectObject = result;
        resolve(projectObject);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateProject = (
  company: string,
  projectId: string,
  updatedProperties: Partial<ProjectObjectUpdate>
): Promise<{ message: string }> => {
  return new Promise((resolve, reject) => {
    const path: string = `/${company}/${projectId}`;
    AuthenticatedApiRequest({
      method: 'post',
      apiName: 'Projects',
      path,
      postData: {
        body: updatedProperties,
      },
    })
      .then((result: any) => {
        if (result.error) {
          const response: { error: string; message: string } = result;
          reject(response);
        } else {
          const response: { message: string } = result;
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addUserToProject = (
  company: string,
  projectId: string,
  userId: string
): Promise<{ message: string }> => {
  return new Promise((resolve, reject) => {
    const path: string = `/${company}/${projectId}/users/${userId}`;
    AuthenticatedApiRequest({
      method: 'put',
      apiName: 'Projects',
      path,
    })
      .then((result: any) => {
        if (result.error) {
          const response: { error: string; message: string } = result;
          reject(response);
        } else {
          const response: { message: string } = result;
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const removeUserFromProject = (
  company: string,
  projectId: string,
  userId: string
): Promise<{ message: string }> => {
  return new Promise((resolve, reject) => {
    const path: string = `/${company}/${projectId}/users/${userId}`;
    AuthenticatedApiRequest({
      method: 'delete',
      apiName: 'Projects',
      path,
    })
      .then((result: any) => {
        if (result.error) {
          const response: { error: string; message: string } = result;
          reject(response);
        } else {
          const response: { message: string } = result;
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createProject = (
  company: string,
  projectId: string,
  projectParams: ProjectRequestObject
): Promise<{ error?: string; message?: string }> => {
  return new Promise((resolve, reject) => {
    const path: string = `/${company}/${projectId}`;
    AuthenticatedApiRequest({
      method: 'put',
      apiName: 'Projects',
      path,
      postData: {
        body: projectParams,
      },
    })
      .then((result: any) => {
        const response: { error?: string; message?: string } = result;
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const prepareTextureReport = (
  company: string,
  projectId: string,
  reportId: string
) =>
  AuthenticatedApiRequest({
    method: 'get',
    apiName: 'Projects',
    path: `/${company}/${projectId}/reports/texture/${reportId}`,
  }) as unknown as Promise<{ message: string; fileRef: string }>;

export const getFaultsReport = (
  company: string,
  projectId: string,
  modelTrainId: string,
  queryParams?: {
    limit?: number;
    offset_token?: number;
  }
) => {
  const validKeys: string[] = ['limit', 'offset_token'];
  return AuthenticatedApiRequest<{
    items: any[];
    next_offset_token: number;
  }>({
    method: 'get',
    apiName: 'Projects',
    path: `/${company}/${projectId}/reports/faults/${modelTrainId}${parseQueryParams(
      validKeys,
      queryParams
    )}`,
  });
};

export const getClassificationReport = (
  company: string,
  projectId: string,
  modelTrainId: string,
  queryParams?: {
    limit?: number;
    offset_token?: number;
  }
) => {
  const validKeys: string[] = ['limit', 'offset_token'];
  return AuthenticatedApiRequest<{
    items: any[];
    next_offset_token: number;
  }>({
    method: 'get',
    apiName: 'Projects',
    path: `/${company}/${projectId}/reports/classifications/${modelTrainId}${parseQueryParams(
      validKeys,
      queryParams
    )}`,
  });
};

export const getPercentageReport = (
  company: string,
  projectId: string,
  reportId: string,
  queryParams?: {
    limit?: number;
    offset_token?: number;
  }
) => {
  const validKeys: string[] = ['limit', 'offset_token'];
  return AuthenticatedApiRequest<{
    items: PercentageReportObject[];
    next_offset_token: number;
  }>({
    method: 'get',
    apiName: 'Projects',
    path: `/${company}/${projectId}/reports/percentage/${reportId}${parseQueryParams(
      validKeys,
      queryParams
    )}`,
  });
};

export const getAllReports = ({
  company,
  projectId,
}: GetProjectAllReportsParams): Promise<ProjectAllReportList> => {
  return AuthenticatedApiRequest({
    method: 'get',
    apiName: 'Projects',
    path: `/${company}/${projectId}/reports/all`,
  }) as unknown as Promise<ProjectAllReportList>;
};
