/** @mui/material imports */
import Button from '@mui/material/Button/Button';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Stack from '@mui/material/Stack/Stack';
/** @mui/icons-material imports */
/** React imports */
import { useTranslation } from 'react-i18next';
/** Goldspot imports */

export interface DialogErrorDetailsProps {
  details: string;
  severity: 'error' | 'warning' | 'info' | 'success';
  isDetailsDialogOpen: boolean;
  onClose: () => void;
}

export const DialogErrorDetails: React.FC<DialogErrorDetailsProps> = ({
  details,
  severity,
  isDetailsDialogOpen,
  onClose,
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Dialog
      maxWidth={'sm'}
      open={isDetailsDialogOpen}
      onClose={onClose}
      className={`dialog-alert-details ${severity}`}
    >
      <DialogTitle>{t('DIALOG_TITLE_ALERT_DETAILS')}</DialogTitle>
      <DialogContent sx={{ py: 0 }}>
        <Stack>
          <pre
            style={{
              overflow: 'auto',
              // @ts-ignore
              'text-wrap': 'wrap',
              maxHeight: '8rem',
            }}
          >
            {details || ''}
          </pre>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button variant="outlined" onClick={onClose}>
          {t('BUTTON_CANCEL')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
