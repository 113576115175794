import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import StylerHOC from './Theme/theme-provider-hoc';
import './utils/localization/i18n';
import './utils/localization/language/authenticator-i18n';
import { GoldspotAuthConfig, AuthenticatorHOC } from 'gsf-util-react-auth';
import { endpoints } from './app-endpoints';

import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey(
  '58f1614c420a139738af737e125265efTz01MDUyNCxFPTE2OTQyNzc2MTI0NzUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

// initialize aws amplify config; pass array of endpoints if needed
GoldspotAuthConfig(endpoints);

const AuthenticatedApp = AuthenticatorHOC(App);
const StyledAndAuthenticatedApp = StylerHOC(AuthenticatedApp);

ReactDOM.render(
  <React.StrictMode>
    <StyledAndAuthenticatedApp />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
