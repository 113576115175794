/** EN */
import general from './en-us/general.json';
import litholens from './en-us/litholens.json';
import projects from './en-us/projects.json';
import company from './en-us/company.json';
import rectify from './en-us/rectify.json';
import atoms_logs_uploader from './en-us/atoms-logs-uploader.json';
/** ES */
import generalES from './es/general.json';
import litholensES from './es/litholens.json';
import projectsES from './es/projects.json';
import companyES from './es/company.json';
import rectifyES from './es/rectify.json';
import atoms_logs_uploaderES from './en-us/atoms-logs-uploader.json';

// merge english sources
export const en = {
  translation: Object.assign(
    {},
    general,
    litholens,
    projects,
    company,
    rectify,
    atoms_logs_uploader
  ),
};

// merge spanish sources
export const es = {
  translation: Object.assign(
    {},
    generalES,
    litholensES,
    projectsES,
    companyES,
    rectifyES,
    atoms_logs_uploaderES
  ),
};
