import React, {
  Context,
  useState,
  createContext,
  useEffect,
  useRef,
} from 'react';
// COMPONENTS
import {
  AlertObject,
  DrillHoleObject,
  ImportedLog,
  ProjectAllReport,
  ProjectAllReportList,
  ProjectObject,
  JobsResponse,
  ProjectModel,
} from './TypeScript/interfaces';
// MUI
import Backdrop from '@mui/material/Backdrop/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
// PACKAGES
import queryString from 'query-string';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  MutationCache,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import { geologContext } from './Contexts/GeologContext';
import AppScopeLoadingComponent from './utils/components/AppScopeLoading';
import { errorHandler } from './utils/error/error-handler';
import {
  getAllReports,
  getProject,
} from './utils/authenticated-requests/projects';
import { fcnetColors, roqenetColors } from './utils/constants';
import { staticColorsList } from './utils/static-colors-list';
import { AppAlertComponent } from './utils/components/AppAlert';
import { useTranslation } from 'react-i18next';
import { MLEnum } from './TypeScript/enums';

const AppRouter = React.lazy(() => {
  return import('./Router/router');
});

interface AppProps {
  user: {
    username: string;
    signInUserSession: {
      idToken: {
        payload: any;
      };
      getIdToken: () => {
        getJwtToken: () => string;
      };
    };
    attributes?: any;
    userDataKey?: string;
  };
  signOut: () => void;
}

// Create a query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, { meta }) => {
      errorHandler(error as Error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      errorHandler(error as Error);
    },
  }),
});

const emptyDrillholesMap: DrillholesMap = {
  byId: {},
  byName: {},
  byQuery: {},
};

export interface DrillholeContextType {
  drillholesMap: DrillholesMap;
  drillholesMapReady: boolean;
  hardReloadDrillholesMap: () => void;
  projectReports: ProjectAllReport[] | [];
  setProjectReports: (value: ProjectAllReport[] | []) => void;
  hardReloadProjectReportsAndModels: () => void;
  projectModels: ProjectModel[];
  projectModelsLoading: boolean;
}

export interface DrillholesMap {
  byId: Record<string | number, DrillHoleObject>;
  byName: Record<string, DrillHoleObject>;
  byQuery: Record<string, DrillHoleObject[]>;
}

export const DrillholeContext = createContext<DrillholeContextType>({
  drillholesMap: emptyDrillholesMap,
  drillholesMapReady: false,
  hardReloadDrillholesMap: (): void => {},
  projectReports: [] as ProjectAllReport[] | [],
  setProjectReports: (value: ProjectAllReport[]): void => {},
  projectModels: [],
  projectModelsLoading: true,
  hardReloadProjectReportsAndModels: (): void => {},
});

export interface AppContextType {
  user: {
    username: string;
    signInUserSession: {
      idToken: {
        payload: any;
      };
      getIdToken: () => {
        getJwtToken: () => string;
      };
    };
    attributes?: any;
    userDataKey?: string;
  };
  company: string; // for switch companies
  setCompany: (value: string | '') => void;
  appAlerts: AlertObject[] | [];
  setAppAlerts: (value: AlertObject[] | []) => void;
  addAppAlert: (value: AlertObject) => void;
  appLoading: boolean;
  setAppLoading: (value: boolean) => void;
  activeProject: ProjectObject;
  setActiveProject: (value: ProjectObject | {}) => void;
  refreshProjectObject: (projectId?: string) => Promise<void>;
  activeJob: JobsResponse;
  setActiveJob: (value: JobsResponse) => void;
}

export interface VisualizationContextType {
  colorLabels: Record<string, Record<string, string>>;
  setColorLabels: (value: Record<string, Record<string, string>> | {}) => void;
  getAvailableColor: () => string;
}

export const appContext: Context<AppContextType> =
  createContext<AppContextType>({
    user: {} as {
      username: string;
      signInUserSession: {
        idToken: {
          payload: any;
        };
        getIdToken: () => {
          getJwtToken: () => string;
        };
      };
      attributes?: any;
      userDataKey?: string;
    },
    company: '', // for switch companies
    setCompany: (value: string) => {},
    appAlerts: [] as AlertObject[] | [],
    setAppAlerts: (value: any): void => {},
    addAppAlert: (value: AlertObject): void => {},
    appLoading: false,
    setAppLoading: (value: boolean): void => {},
    // The project is selected via URL
    activeProject: {},
    setActiveProject: (value: ProjectObject): void => {},
    refreshProjectObject: (projectId?: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        resolve();
      });
    },
    activeJob: {
      company: '',
      description: '',
      id: '',
      project: '',
    },
    setActiveJob: (value: JobsResponse): void => {},
  });

export const VisualizationContext: Context<VisualizationContextType> =
  createContext<VisualizationContextType>({
    colorLabels: {},
    setColorLabels: (value: Record<string, Record<string, string>>): void => {},
    getAvailableColor: (): string => '',
  });

export const GeologContext = geologContext;
GeologContext.displayName = 'GeologContext';

const App: React.FC<AppProps> = ({ user, signOut }): JSX.Element => {
  // High level state to control the Side Drawer via sibbling Navbar
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [appLoading, setAppLoading] = useState<boolean>(false);
  const [company, setCompany] = useState<string>(
    user.signInUserSession.idToken.payload['custom:company']
  ); //default company
  const [activeProject, setActiveProject] = useState<ProjectObject>({});
  const [activeJob, setActiveJob] = useState<JobsResponse>({
    company: '',
    description: '',
    id: '',
    project: '',
  });
  const [appAlerts, setAppAlerts] = useState<AlertObject[] | []>([]);
  const [colorLabels, setColorLabels] = useState<
    Record<string, Record<string, string>>
  >({
    [MLEnum.veinnet]: { [MLEnum.veinnet]: '#f87ff0' },
    [MLEnum.roqenet]: { ...roqenetColors },
    [MLEnum.fcnet]: { ...fcnetColors },
    noData: { noData: '#FFF' },
    userLogColorLabel: {},
  }); // {roqenet: {label: color}}
  const [drillholesMap, setDrillholesMap] =
    useState<DrillholesMap>(emptyDrillholesMap);
  const [drillholesMapReady, setDrillholesMapReady] = useState<boolean>(false);
  const [projectReports, setProjectReports] = useState<ProjectAllReport[]>([]);
  const [projectModels, setProjectModels] = useState<ProjectModel[]>([]);
  const [projectModelsLoading, setProjectModelsLoading] =
    useState<boolean>(true);
  const [logsMetadata, setLogsMetadata] = useState<ImportedLog[]>([]);
  const projectIdRef = useRef<string>();
  const allDrillholesWorkerRef = useRef<Worker>();
  const drillholesMapRef = useRef<DrillholesMap>(emptyDrillholesMap);
  const geologsRef = useRef<
    Record<string, Record<string | number, Record<string, any>[]>>
  >({});
  const availableColorsRef = useRef<string[]>([...staticColorsList]);
  const { i18n } = useTranslation();

  /**
   * When app loads, start worker
   */
  useEffect(() => {
    initAllDrillholesWorker();
  }, []);

  /**
   * When active project changes, update drillhole context
   */
  useEffect(() => {
    const currentProjectId: string | undefined = activeProject?.id;
    const previousProjectId: string = `${projectIdRef.current}`;
    // maintain ref to project id to compare changes
    projectIdRef.current = currentProjectId;
    //handle differences:
    if (previousProjectId && !currentProjectId) {
      // left project scope > clear drillholes
      resetAllDrillholesWorker();
      resetDrillholesMap();
      resetGeologsContext();
      setProjectReports([]);
      setProjectModels([]);
    } else if (currentProjectId && currentProjectId !== previousProjectId) {
      // entered into or swapped project scope > replace drillholes
      resetAllDrillholesWorker();
      resetDrillholesMap();
      fetchAllDrillholes();
      resetGeologsContext();
      fetchReportsAndModels(currentProjectId);
    }
    // else currentProjectId === previousProjectId => no action
  }, [activeProject]);

  /**
   * get/set app language at app init (changes handled in LanguageSwitcher component)
   */
  useEffect(() => {
    const supportsLocalStorage: boolean = window.hasOwnProperty('localStorage');
    const selectedLanguage: string =
      window.localStorage?.getItem(
        `${user.userDataKey}.selectedLanguage.litholens`
      ) || '';
    const defaultLanguage: string = i18n.options.lng || 'en';
    if (supportsLocalStorage && !selectedLanguage) {
      window.localStorage.setItem(
        `${user.userDataKey}.selectedLanguage.litholens`,
        defaultLanguage
      );
    } else if (user && supportsLocalStorage && selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [user]);

  const fetchReportsAndModels = (projectId: string) => {
    setAppLoading(true);
    setProjectModelsLoading(true);
    getAllReports({
      company: company,
      projectId,
    })
      .then((data: ProjectAllReportList) => {
        if (data?.items) {
          setProjectReports(data.items);
        } else {
          setProjectReports([]);
        }
        if (data?.models) {
          setProjectModels(data.models);
        } else {
          setProjectModels([]);
        }
      })
      .catch((error) => {
        setProjectReports([]);
        setProjectModels([]);
        errorHandler(error);
      })
      .finally(() => {
        setProjectModelsLoading(false);
        setAppLoading(false);
      });
  };

  /**
   * Terminate the drillholes worker and re-initialize
   *
   * stops fetching drillholes if fetch is in progress
   */
  const resetAllDrillholesWorker = (): void => {
    if (allDrillholesWorkerRef.current) {
      allDrillholesWorkerRef.current.terminate();
      allDrillholesWorkerRef.current = undefined;
      initAllDrillholesWorker();
    }
  };

  /**
   * Initialize drillhole worker
   *
   * instantiates worker and sets event handlers
   */
  const initAllDrillholesWorker = (): void => {
    if (!allDrillholesWorkerRef.current) {
      allDrillholesWorkerRef.current = new Worker(
        '/get-all-drillholes-by-project.js'
      );
    }
    if (allDrillholesWorkerRef.current) {
      const queryString: string = JSON.stringify({
        expand: true,
      });
      allDrillholesWorkerRef.current.onmessage = (
        message: MessageEvent<{ results: DrillHoleObject[]; last: boolean }>
      ) => {
        setAppLoading(true);
        const { results, last } = message.data;
        populateDrillholeMap(queryString, results);
        if (last) {
          setAppLoading(false);
        }
      };
      allDrillholesWorkerRef.current.onerror = (e: ErrorEvent) => {
        errorHandler(e.error);
        populateDrillholeMap(queryString);
        setAppLoading(false);
      };
    }
  };

  /**
   * Resets drillholes map and fetches drillholes from api
   */
  const hardReloadDrillholesMap = (): void => {
    resetDrillholesMap();
    fetchAllDrillholes();
  };

  const hardReloadProjectReportsAndModels = (): void => {
    if (activeProject.id) {
      fetchReportsAndModels(activeProject.id);
    }
  };

  /**
   * Fetch all drillholes
   *
   * starts fetch in web worker
   */
  const fetchAllDrillholes = (): void => {
    if (activeProject && activeProject.id && allDrillholesWorkerRef.current) {
      setDrillholesMapReady(false);
      const apiHostname: string = `https://projects.${
        process.env.REACT_APP_ENVIRONMENT === 'test' ? 'test.' : ''
      }api.goldspot.ca`;
      const bearerToken: string = user.signInUserSession
        .getIdToken()
        .getJwtToken();
      allDrillholesWorkerRef.current.postMessage({
        apiHostname,
        company,
        project: activeProject.id,
        bearerToken,
      });
    } else {
      const err: Error = new Error('no active project');
      errorHandler(err);
      setDrillholesMapReady(true);
    }
  };

  /**
   * clear all logs from geologs context
   *
   * used when switching projects
   */
  const resetGeologsContext = (): void => {
    geologsRef.current = {};
    setLogsMetadata([]);
  };

  /**
   * Reset drillholes map to empty
   *
   * used for when active project removed from context
   */
  const resetDrillholesMap = (): void => {
    const emptyDrillholesMap: DrillholesMap = {
      byId: {},
      byName: {},
      byQuery: {},
    };
    drillholesMapRef.current = { ...emptyDrillholesMap };
    setDrillholesMap({ ...emptyDrillholesMap });
  };

  /**
   * Put drillholes objects into maps and add to context
   * @param queryString json.stringified version of the following object:
   * ```
   * const queryString = {
   *   limit: number,
   *   expand: boolean,
   * };
   * ```
   * TODO: expand object with orderby, sortby, etc if it becomes available
   * @param drillholes array of drillhole objects
   */
  const populateDrillholeMap = (
    queryString: string,
    drillholes: DrillHoleObject[] = []
  ): void => {
    if (!drillholesMapRef.current.byQuery[queryString]) {
      drillholesMapRef.current.byQuery[queryString] = [];
    }
    drillholes.forEach((drillhole: DrillHoleObject) => {
      // if drillhole not yet saved or saved drillhole is not expanded
      if (
        !drillholesMapRef.current.byId.hasOwnProperty(drillhole.id) ||
        !drillholesMapRef.current.byId[drillhole.id].hasOwnProperty('length')
      ) {
        drillholesMapRef.current.byId[drillhole.id] = drillhole;
        drillholesMapRef.current.byName[drillhole.name] =
          drillholesMapRef.current.byId[drillhole.id];
      }
      drillholesMapRef.current.byQuery[queryString].push(
        drillholesMapRef.current.byId[drillhole.id]
      );
    });
    setDrillholesMap({ ...drillholesMapRef.current });
    setDrillholesMapReady(true);
  };

  /**
   * Add imported logs to app
   *
   * (GeologsContext)
   */
  const addLogs = (
    logs: Record<string, Record<string | number, Record<string, any>[]>>
  ): void => {
    Object.assign(geologsRef.current, logs);
  };

  /**
   * Get imported logs from app
   *
   * (GeologsContext)
   */
  const getLogs = (
    logName: string,
    drillholeId: string | number
  ): Record<string, any>[] => {
    return geologsRef.current[logName]?.[drillholeId] || [];
  };

  /**
   * Add imported log objects to list in app
   *
   * (GeologsContext)
   */
  const addLogsMetadata = (newLogsMetadata: ImportedLog[]): void => {
    const updatedLogsMetadata: ImportedLog[] = [...logsMetadata];
    newLogsMetadata.forEach((importedLog: ImportedLog) => {
      // remove duplicates before appending new items
      if (logsMetadata.length > 0) {
        // iterate in reverse so that splice doesn't corrupt loop
        logsLoop: for (let i = logsMetadata.length - 1; i >= 0; i--) {
          const existingLog: ImportedLog = logsMetadata[i];
          const metadataItem: (keyof ImportedLog)[] = [
            'fileName',
            'columnHeader',
          ];
          let different: boolean = false;
          propertiesLoop: for (let j = 0; j < metadataItem.length; j++) {
            const property: keyof ImportedLog = metadataItem[j];
            if (importedLog[property] != existingLog[property]) {
              different = true;
              break propertiesLoop;
            }
          }
          if (!different) {
            updatedLogsMetadata.splice(i, 1);
            // if duplicate is found, exit loop
            break logsLoop;
          }
        }
      }
      updatedLogsMetadata.push(importedLog);
    });
    setLogsMetadata(updatedLogsMetadata);
  };

  const addAppAlert = (alert: AlertObject): void => {
    setAppAlerts([...appAlerts, alert]);
  };

  const handleCloseAlert = (): void => {
    if (appAlerts.length > 0) {
      let tempAlerts = [...appAlerts];
      tempAlerts.shift(); // Removes the first Alert (closing one Aler at a time)
      setAppAlerts([...tempAlerts]);
    }
  };

  /**
   * Get project object and update context
   */
  const refreshProjectObject = (projectId?: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const project = projectId || activeProject?.id;
      resetDrillholesMap();
      if (project) {
        getProject(company, project as string, true)
          .then((value: ProjectObject) => {
            setActiveProject(value);
            resolve();
          })
          .catch((err) => {
            errorHandler(err);
            reject(err);
          });
      } else {
        resolve();
      }
    });
  };

  /**
   * Gets a unique color from a static list of colors
   * @returns CSS-valid hexadecimal color string
   */
  const getAvailableColor = (): string => {
    const color: string = availableColorsRef.current.shift() || '#333333';
    return color;
  };

  /*
   * Checks if drillhole map is complete based on drillholes count in active project
   * @param project active project
   * @param drillholesMap drillholes map
   * @returns true if drillholes map is complete
   */
  const isDrillholesMapReady = (
    project: ProjectObject,
    drillholesMap: any
  ): boolean => {
    const numberOfDrillholes: number | undefined =
      project.litholensData?.drillholesCount === null
        ? 0
        : project.litholensData?.drillholesCount;
    const drillholesReady: number = (Object.keys(drillholesMap.byId) || [])
      .length;
    if (
      numberOfDrillholes !== undefined &&
      drillholesReady === numberOfDrillholes
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (
      activeProject.litholensData?.drillholesCount &&
      activeProject.litholensData.drillholesCount > 0
    ) {
      fetchAllDrillholes();
    }
  }, [activeProject]);

  // maintain `drillholesMapReady`
  useEffect(() => {
    const isReady: boolean = isDrillholesMapReady(activeProject, drillholesMap);
    setDrillholesMapReady(isReady);
  }, [activeProject, drillholesMap]);

  return (
    <BrowserRouter>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: (searchString) =>
            queryString.parse(searchString, {
              arrayFormat: 'comma',
            }),
          objectToSearchString: (encodedParams) =>
            queryString.stringify(encodedParams, {
              arrayFormat: 'comma',
              encode: false,
            }),
        }}
      >
        <QueryClientProvider client={queryClient}>
          <appContext.Provider
            value={{
              user,
              appAlerts,
              setAppAlerts,
              addAppAlert,
              appLoading,
              setAppLoading,
              company,
              setCompany,
              activeProject,
              setActiveProject,
              refreshProjectObject,
              activeJob,
              setActiveJob,
            }}
          >
            <DrillholeContext.Provider
              value={{
                drillholesMap,
                drillholesMapReady,
                hardReloadDrillholesMap,
                projectReports,
                setProjectReports,
                projectModels,
                projectModelsLoading,
                hardReloadProjectReportsAndModels,
              }}
            >
              <VisualizationContext.Provider
                value={{
                  colorLabels,
                  setColorLabels,
                  getAvailableColor,
                }}
              >
                <GeologContext.Provider
                  value={{
                    addLogs,
                    getLogs,
                    logsMetadata,
                    addLogsMetadata,
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <React.Suspense fallback={<AppScopeLoadingComponent />}>
                      <AppRouter
                        user={user}
                        setIsDrawerOpen={setIsDrawerOpen}
                        isDrawerOpen={isDrawerOpen}
                        signOut={signOut}
                      />
                    </React.Suspense>
                  </Box>
                  <Backdrop
                    sx={{ color: '#fff', zIndex: '2000' }}
                    open={appLoading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  {appAlerts.length > 0 && (
                    <AppAlertComponent
                      appAlert={appAlerts[0]}
                      handleCloseAlert={handleCloseAlert}
                    />
                  )}
                </GeologContext.Provider>
              </VisualizationContext.Provider>
            </DrillholeContext.Provider>
          </appContext.Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default App;
